<template>
<v-simple-table>
  <template v-slot:default>
    <thead>
    <tr>
      <td>
        <v-btn @click="loadReSubmits" color="primary" small fab>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </td>
    </tr>
    <tr>
      <th>License No.</th>
      <th>Inventory External ID</th>
      <th>Test Name</th>
      <th>Test Value</th>
      <th>Test Date</th>
      <th>Lab Test Status</th>
      <th>Created By</th>
      <th>Created Date</th>
      <th>Update By</th>
      <th>Updated Date</th>
      <th>Operation</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in reSubmits" :key="item.id">
      <td>{{ item.license_number }}</td>
      <td>{{ item.inventory_external_identifier }}</td>
      <td>{{ item.test_name }}</td>
      <td>{{ item.test_value }}</td>
      <td>{{ item.test_date }}</td>
      <td>{{ item.lab_test_status }}</td>
      <td>{{ item.created_by }}</td>
      <td>{{ item.created_date }}</td>
      <td>{{ item.updated_by }}</td>
      <td>{{ item.updated_date }}</td>
      <td>{{ item.operation }}</td>
    </tr>
    <tr>
      <th colspan="11">
        <v-btn color="primary" @click="prepareReSubmitsData">Prepare ReSubmits Data</v-btn>
      </th>
    </tr>
    </tbody>
  </template>
</v-simple-table>
</template>

<script>
import ccrsApi from "@/lib/ccrs-api";

export default {
  name: "ReSubmitResults",
  mounted() {
    this.loadReSubmits();
  },
  data () {
    return {
      loading: false,
      reSubmits: [],
    }
  },
  methods: {
    async loadReSubmits() {
      this.loading = true;
      let query = await ccrsApi.get('/v1/labResults/reSubmit/list');
      this.reSubmits = query.data;
      // console.log(query.data);
      this.loading = false;
    },
    async prepareReSubmitsData() {
      this.loading = true;
      // eslint-disable-next-line no-unused-vars
      let query = await ccrsApi.get('/v1/labResults/reSubmit/prepare');
      this.loading = false;
      window.location.reload();
    },
  },
}
</script>

<style scoped>

</style>